.projectssection{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 30px;
    row-gap: 30px;
}

@media screen and (max-width: 599px)  {
    .projectssection{
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 30px;
        row-gap: 30px;
    }    
}

@media screen and (max-width: 800px) and (min-width: 600px) {
    .projectssection{
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 30px;
        row-gap: 30px;
    }    
}