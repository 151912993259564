.App{
  background-color: #B1B9B3;
  min-height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 1220px) {
  .App{
      padding: 8px;
  }
}