.brand-name  {
    font-family: "Fredericka the Great", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 54px;
} 

.section-head {
    font-family: 'Calisto MT';
    font-size: 19px;
}



@media only screen and (max-width: 1220px) {
    .brand-name {
        font-size: 48px;
    }
}

@media only screen and (max-width: 650px) {
    .section-head{
        display: none;
    }

    .brand-name {
        font-size: 40px;
    }

    .hamburger{
        background-color: #213827;
        width: 50px;
        height: 20px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 10px 0px;
    }
    
    .hamburger span{
        display: block;
        width: 30px;
        background-color: #B1B9B3;
        height: 1px;
    }
}