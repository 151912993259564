.intro-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: end;
}

.about{
    padding-bottom: 20px;
    font-family: 'Calisto MT';
    font-size: 32px;
}

.image{
    width: 100%;
}

@media only screen and (max-width: 650px) {
    .intro-container{
        display: flex;
        flex-direction: column-reverse;
    }
    .image{
        display: none;
    }
}